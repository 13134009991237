@font-face {
  font-family: 'Open Sauce One';
  src: url('/fonts/open-sauce-one/OpenSauceOne-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sauce One';
  src: url('/fonts/open-sauce-one/OpenSauceOne-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Orbitron';
  src: url('/fonts/orbitron/Orbitron-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mortend';
  src: url('/fonts/mortend/Mortend-Light.ttf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mortend';
  src: url('/fonts/mortend/Mortend-Bold.ttf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mortend';
  src: url('/fonts/mortend/Mortend-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mortend Outline';
  src: url('/fonts/mortend/Mortend-ExtraBoldOutline.ttf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
